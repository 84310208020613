body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

h1 a {
font-size: 1.8rem;
}
h1 a.text-light:hover {
color: white!important;
text-decoration: none!important;
}
.nuevo-post{
  font-weight: bold;
  text-transform: uppercase;
}

.btn {
  font-weight: bold;
}
.form-group label,
.list-group-item {
  font-size: 1.4rem;
}

.navbar .btn {
flex:1;
}
@media (min-width:768px){
.navbar .btn {
  flex: 0;
}
}
.acciones {
margin: 1rem 0;
}
@media (min-width:768px) {
.acciones{
  margin: 0;
}
}
.acciones > *{
flex: 1;
}
