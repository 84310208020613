body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.containersignpad { 
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 500;
  height: 400;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
